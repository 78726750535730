<template>
    <c-table ref="tableRef" :tableDataUrl="tableDataUrl" :border="true" :isLoadData="true">
        <el-table-column type="index" width="55" label="序号" :align="'center'" />
        <el-table-column prop="settlement_number" label="结算单号" />
        <el-table-column prop="money" label="结算金额" />
        <el-table-column prop="payment_money" label="打款金额" />
        <el-table-column prop="payment_people" label="打款人" />
        <el-table-column prop="settlement_card" label="结算卡号" />
        <el-table-column prop="bank_address" label="银行地址" />
        <el-table-column prop="card_name" label="银行卡持有人" />
        <el-table-column prop="status" label="结算状态">
            <template #default="scope">
                <span v-if="scope.row.status == 10">
                    <el-tag type="info">未结算</el-tag>
                </span>
                <span v-if="scope.row.status == 20">
                    <el-tag type="info">结算中</el-tag>
                </span>
                <span v-if="scope.row.status == 30">
                    <el-tag type="info">结算完成</el-tag>
                </span>
            </template>
        </el-table-column>
        <el-table-column prop="created_at" label="打款时间">
            <template #default="scope">
                {{ timeFormat(scope.row.created_at, 'datetime') }}
            </template>
        </el-table-column>
    </c-table>
</template>

<script setup>
import { getCurrentInstance } from 'vue'
import cTable from '@/components/CustomTable'
import { timeFormat } from '@/func/time'

const { proxy } = getCurrentInstance() // 当前实例
const tableDataUrl = proxy.$api.settlement.record


</script>